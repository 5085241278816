import React, { useEffect, useState } from "react";
import "./conversation-details.scss";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  ChatConversationEntity,
  ChatMessageEntity,
} from "../../../../../../generated/graphql";
import { eventBus } from "../../../../../../event-bus/event-bus";
import { AvatarStack } from "../../../../common/avatar-stack/avatar-stack";
import { UserAvatar } from "../../../../common/user-avatar/user-avatar";

const token = localStorage.getItem("token");
let userId: number;

if (token) {
  const details = JSON.parse(atob(token!.split(".")[1]));
  userId = details.userId;
} else {
  userId = 0;
}

const isMobile = window.innerWidth < 550;

const CONVERSATION_DETAILS = gql`
  query GetConversationMessages($conversation_id: Int!) {
    getConversationMessages(conversation_id: $conversation_id) {
      id
      from_user
      message
      type
      section {
        id
        section_header
      }
      user {
        id
        first_name
        last_name
        username
        avatar_url
      }
    }
    getConversationDetails(conversation_id: $conversation_id) {
      id
      initiative {
        id
        name
      }
      section {
        id
        section_header
      }
      participants {
        id
        first_name
        last_name
        avatar_url
      }
    }
  }
`;

const SEND_MESSAGE = gql`
  mutation SendMessage($conversation_id: Int!, $message: String!) {
    sendMessage(conversation_id: $conversation_id, message: $message) {
      id
      message
      conversation_id
    }
  }
`;

interface ConversationData {
  getConversationMessages: ChatMessageEntity[];
  getConversationDetails: ChatConversationEntity;
}

interface Props {
  conversation_id: number;
}

export const ConversationDetails = (props: Props) => {
  const { data, error, loading, refetch } = useQuery<ConversationData>(
    CONVERSATION_DETAILS,
    {
      variables: { conversation_id: props.conversation_id },
      skip: props.conversation_id === 0,
    }
  );

  const [sendMessage] = useMutation(SEND_MESSAGE, {
    update: (cache, response) => {
      refetch();
    },
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      console.log("Refetching the messages");
      // refetch();
    }, 2000);

    return () => {
      console.log("Unmounting the component");
      clearInterval(intervalId);
    };
  }, []);

  const [message, setMessage] = useState("");
  const [collapsed, setCollapsed] = useState(false);

  if (loading) {
    return <div>Loading</div>;
  }

  console.log("Conversation Details: ", data);

  const otherParticipants = data?.getConversationDetails.participants.filter(
    (p) => p.id !== userId
  );

  if (!otherParticipants?.length) {
    return <div>No participants</div>;
  }

  const participants = (
    <div className="participants">
      <div className="participant">
        <AvatarStack users={otherParticipants} />
        {/* <img className="avatar" src={otherParticipants![0].avatar_url!} /> */}
        <div className="details">
          <div className="initiative-name">
            {data?.getConversationDetails.initiative?.name}
          </div>
          <div className="participant-name">
            {otherParticipants![0].first_name}
          </div>
        </div>
      </div>
    </div>
  );

  const _handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      sendMessage({
        variables: {
          message,
          conversation_id: props.conversation_id,
        },
      });
      setMessage("");
    }
  }

  return (
    <div
      className={`conversation-details pb-14 relative ${isMobile ? "" : "floating"} ${
        collapsed ? "collapsed" : ""
      }`}
    >
      {!isMobile && (
        <div className="bg-blue-500 toolbar">
          {!isMobile && participants}
          <div className="toolbar-buttons">
            <div
              className="toolbar-button minimise"
              onClick={() => setCollapsed(!collapsed)}
            >
              _
            </div>
            <div
              className="toolbar-button"
              onClick={() => {
                eventBus.dispatch("CHAT_CONVERSATION_CLOSED", {});
              }}
            >
              x
            </div>
          </div>
        </div>
      )}

      {isMobile && <div className="heading">{participants}</div>}
      <div className="messages">
        {data?.getConversationMessages.map((message) => {
          if (message.type === "SEPARATOR") {
            if (message.section) {
              return (
                <div key={message.id} className="separator-message">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {message.section!.section_header}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              );
            } else {
              return [];
            }
          } else {
            return (
              <div
                key={message.id}
                className={`conversation-message ${
                  message.from_user === userId ? "my-message" : "other-message"
                }`}
              >
                <div>
                  <UserAvatar size="8" user={message.user!} />
                </div>
                <div className="message-container">{message.message}</div>
              </div>
            );
          }
        })}
      </div>
      <div className="absolute bottom-0 w-full send-container">
        <input
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={_handleKeyDown}
          className="typed-message"
          type="text"
          placeholder="Type your message here"
        />
        <div
          onClick={() => {
            if (!message) {
              return;
            }
            sendMessage({
              variables: {
                message,
                conversation_id: props.conversation_id,
              },
            });
            setMessage("");
          }}
          className={`message-send-button ${message ? "" : "disabled"}`}
        >
          Send
        </div>
      </div>
    </div>
  );
};
